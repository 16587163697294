import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import App from './App.vue'
import router from './router'
import VueIntersect from 'vue-intersect'
import 'animate.css/animate.min.css'
const app = createApp(App)
app.directive('animate', {
  mounted(el, binding) {
    let addedClass = false;

    const scrollHandler = () => {
      const { top } = el.getBoundingClientRect();
      const h = window.innerHeight;
      if (top < h && !addedClass) {
        el.classList.add(binding.value);
        addedClass = true;
        // 移除滚动事件监听，因为元素已经触发了动画
        window.removeEventListener('scroll', scrollHandler, true);
      }
    };

    // 添加滚动事件监听
    window.addEventListener('scroll', scrollHandler, true);
  },
})

app.use(router).use(ElementPlus).use(VueIntersect).mount('#app')
