<template>
  <div class="home ">
    <el-image class="home-banner" src="https://rapantaohui.oss-cn-hangzhou.aliyuncs.com/images/website/homeHanner.png" fit="cover"></el-image>
    <div class="home-regard">
        <div class="regard-top">
            <div class="regard-top-cont">
              <div class="headline">
                <div class="headline-title animate__animated" v-animate="'animate__bounceInLeft'">关于我们</div>
                <div class="headline-interpret animate__animated" v-animate="'animate__bounceInLeft'">ABOUT US</div>
                <div class="headline-line"></div>
              </div>
              <div class="regard-top-cont-text">
                蟠桃汇（杭州）互联网科技有限公司专注于互联网医疗器械信息服务，坚持"以市场为导
                向以客户为中心”的理念，致力于为 广大用户提供可靠有效的医疗器械信息服务，我们秉
                承“先进理念++高效平台”的服务精髓，帮助客户提供所需医疗器械信息。<br/>
                公司会不断提供优质医疗器械信息的同时努力提升自己的服务水平, 以让客户达到更大的
                满意为我们永远的服务宗旨, 在医药信息领域中。感谢您对蟠桃汇（杭州）互联网科技有限公司的支持，我们会继续努力。
              </div>
            </div>
            <el-image class="egard-top-img" src="https://rapantaohui.oss-cn-hangzhou.aliyuncs.com/images/website/homeRegard.png" fit="cover"></el-image>
        </div>
        <div class="regard-bom">
            <div class="regard-bom-item" v-for="(item,index) in regardList" :key="index">
                <div class="regard-item-img animate__animated" v-animate="'animate__zoomIn'">
                  <img :src="item.url" alt="" srcset="">
                </div>
                <div class="regard-item-name">{{item.name}}</div>
                <div class="regard-item-text">{{item.text}}</div>
            </div>
        </div>
    </div>
    <div class="home-product">
      <div class="headline">
          <div class="headline-title animate__animated" v-animate="'animate__bounceInLeft'">产品示例</div>
          <div class="headline-interpret animate__animated" v-animate="'animate__backInRight'">PRODUCT EXAMPLES</div>
          <div class="headline-line"></div>
      </div>
      <div class="product-list">
          <div class="product-list-item" v-for="(item,index) in productList" :key="index">
            <img class="product-item-img" :src="item.url" alt="" srcset="">
            <div class="product-item-text">{{ item.name }}</div>
          </div>
      </div>
    </div>
    <div class="home-news">
      <div class="headline">
          <div class="headline-title animate__animated" v-animate="'animate__bounceInLeft'">新闻动态</div>
          <div class="headline-interpret animate__animated" v-animate="'animate__backInRight'">NEWS FEED</div>
          <div class="headline-line"></div>
      </div>
      <div class="news-list">
          <div class="news-item" v-for="(item,index) in newsInfos" :key="index" @click="onDetail(item)">
            <img class="news-item-img" :src="item.imageUrl" alt="cover">
            <div class="news-item-right">
                <div class="item-right-title">{{ item.title }}</div>
                <div class="item-right-text">{{ item.tip }}</div>
                <div class="item-right-time">{{ item.date }}</div>
                <div class="item-right-btn">查看详情 ></div>
              </div>
          </div>
          <div class="news-more animate__animated" v-animate="'animate__flipInY'" @click="more" v-if="butShow">MORE</div>
      </div>
    </div>
    <div class="home-buddy">
      <div class="headline">
          <div class="headline-title animate__animated" v-animate="'animate__bounceInLeft'">合作伙伴</div>
          <div class="headline-interpret animate__animated" v-animate="'animate__backInRight'">COOPERATION PARTNERS</div>
          <div class="headline-line"></div>
      </div>
      <div class="home-buddy-list">
          <div class="buddy-item animate__animated" v-animate="'animate__zoomIn'" v-for="(item,index) in buddyList" :key="index" :style="item.style">
              <img :src="item.url" alt="" srcset="">
          </div>
      </div>
    </div>
  </div>
  
</template>

<script setup>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'
import { useRouter } from "vue-router";
import newsInfo from "../assets/newsInfo.json"
import { ref,reactive } from 'vue';
const router = useRouter();
const newsInfos = ref(newsInfo.slice(0,3))
const butShow = ref(true)
const onDetail = (item) => {
  console.log(item)
  let value = JSON.stringify(item)
  router.push({ name: "detail",params:{data:encodeURIComponent(value)} })
}
const more = ()=>{
  // console.log('jiazai',newsInfo)
  newsInfos.value = [...newsInfo]
  butShow.value = false
}
const onIntersect = (entry, observer) => {
      if (entry.isIntersecting) {
        // 当元素进入视口时触发的动作
        console.log(123)
        alert(123)
        observer.unobserve(entry.target); // 停止监测
      }
}
const regardList = ref([
  {
    url:'https://rapantaohui.oss-cn-hangzhou.aliyuncs.com/images/website/service.png',
    name:'专属客服',
    text:'1对1服务 优先快捷'
  },
  {
    url:'https://rapantaohui.oss-cn-hangzhou.aliyuncs.com/images/website/brand.png',
    name:'品牌专享',
    text:'大牌品质有保障 汇聚全球好物'
  },
  {
    url:'https://rapantaohui.oss-cn-hangzhou.aliyuncs.com/images/website/defend.png',
    name:'守护健康',
    text:'健康为首 邀您护友'
  },
  {
    url:'https://rapantaohui.oss-cn-hangzhou.aliyuncs.com/images/website/scale.png',
    name:'万亿规模',
    text:'大健康产业未来发展前景无限'
  }
])

const productList = ref([
  {
    url:'https://rapantaohui.oss-cn-hangzhou.aliyuncs.com/images/website/drink.png',
    name:'金御橘丝饮'
  },
  {
    url:'https://rapantaohui.oss-cn-hangzhou.aliyuncs.com/images/website/yeepay.png',
    name:'易宝通片'
  },
  {
    url:'https://rapantaohui.oss-cn-hangzhou.aliyuncs.com/images/website/albumen.png',
    name:'螺旋藻复合蛋白肽饮料'
  },
  {
    url:'https://rapantaohui.oss-cn-hangzhou.aliyuncs.com/images/website/grape.png',
    name:'复合花椰菜葡萄压片糖果'
  },
  {
    url:'https://rapantaohui.oss-cn-hangzhou.aliyuncs.com/images/website/kind.png',
    name:'天方仙草 铁皮石斛粗细粉'
  },
  {
    url:'https://rapantaohui.oss-cn-hangzhou.aliyuncs.com/images/website/fish.png',
    name:'百合康牌深海鱼油'
  }
])
const buddyList = ref([
  {
    url:'https://rapantaohui.oss-cn-hangzhou.aliyuncs.com/images/website/tomson.png',
    style:'width:139px;height:48px;'
  },
  {
    url:'https://rapantaohui.oss-cn-hangzhou.aliyuncs.com/images/website/conba.png',
    style:'width:140px;height:49px;'
  },
  {
    url:'https://rapantaohui.oss-cn-hangzhou.aliyuncs.com/images/website/tongrentang.png',
    style:'width:139px;height:46px;'
  },
  {
    url:'https://rapantaohui.oss-cn-hangzhou.aliyuncs.com/images/website/ginza.png',
    style:'width:154px;height:50px;'
  },
  {
    url:'https://rapantaohui.oss-cn-hangzhou.aliyuncs.com/images/website/lamer.png',
    style:'width:154px;height:50px;'
  },
  {
    url:'https://rapantaohui.oss-cn-hangzhou.aliyuncs.com/images/website/Hpson.png',
    style:'width:139px;height:50px;'
  },
  {
    url:'https://rapantaohui.oss-cn-hangzhou.aliyuncs.com/images/website/swisse.png',
    style:'width:139px;height:50px;'
  },
  {
    url:'https://rapantaohui.oss-cn-hangzhou.aliyuncs.com/images/website/amend.png',
    style:'width:146px;height:60px;'
  },
  {
    url:'https://rapantaohui.oss-cn-hangzhou.aliyuncs.com/images/website/hapharm.png',
    style:'width:154px;height:46px;'
  },
  {
    url:'https://rapantaohui.oss-cn-hangzhou.aliyuncs.com/images/website/peaceful.png',
    style:'width:154px;height:50px;'
  },
])
</script>
<style lang="scss" scoped>
.animate__animated.animate__bounceInLeft {
  --animate-duration: 2s;
}
.animate__animated.animate__backInRight {
  --animate-duration: 2s;
}
  .home-banner{
    width: 100%;
  }
  @keyframes movement {
    from{
      margin-left: -100px;
    }
    to{
      margin-left: 0px;
    }
  }
  .animated-movement{
    animation: movement ease-in-out 1s forwards;
  }
  .home-regard{
    width: 100%;
    height: 865px;
    padding: 0px 18.75%;
    padding-top: 158px;
    margin-top: -10px;
    box-sizing: border-box;
    background-color: #FAFAFA;
    .regard-top{
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .regard-top-cont{
        flex: 1;
        height: 359px;
        margin-right: 96px;
        .headline{
          display: flex;
          flex-direction: column;
          justify-content: center;
          .headline-title{
            font-size: 30px;
            font-weight: 400;
            color: #000000;
          }
          .headline-interpret{
            font-size: 18px;
            font-weight: 400;
            color: #666666;
            margin-top: 21px;
            margin-bottom: 28px;
          }
          .headline-line{
            width: 39px;
            height: 1px;
            background-color: #666666;
          }
        }
        .regard-top-cont-text{
          width: 100%;
          font-size: 16px;
          font-weight: 400;
          color: #666666;
          line-height: 28px;
          margin-top: 59px;
        }
      }
      .egard-top-img{
        width: 483px;
        height: 359px;
      }
    }
    .regard-bom{
      width: 100%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      margin-top: 100px;
      .regard-bom-item{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .regard-item-img{
          width: 80px;
          height: 80px;
          img{
            width: 100%;
            height: 100%;
          }
        }
        .regard-item-name{
          font-size: 16px;
          font-weight: 400;
          color: #333333;
          margin-top: 26px;
          margin-bottom: 22px;
        }
        .regard-item-text{
          font-size: 14px;
          font-weight: 400;
          color: #999999;
        }
      }
    }
  }
  .home-product{
    width: 1300px;
    min-height: 1143px;
    // padding: 0px 18.75%;
    margin: 0 auto;
    padding-top: 100px;
    box-sizing: border-box;
    .headline{
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .headline-title{
            font-size: 30px;
            font-weight: 400;
            color: #000000;
          }
          .headline-interpret{
            font-size: 18px;
            font-weight: 400;
            color: #666666;
            margin-top: 21px;
            margin-bottom: 28px;
          }
          .headline-line{
            width: 39px;
            height: 1px;
            background-color: #666666;
          }
      }
      .product-list{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        margin-top: 80px;
        .product-list-item{
          width: 403px;
          height: 380px;
          background: #FFFFFF;
          padding: 15px;
          box-sizing: border-box;
          margin-right: 10px;
          margin-bottom: 35px;
          transition: box-shadow 0.4s;
          cursor: pointer;
          .product-item-img{
            width: 373px;
            height: 320px;
          }
          .product-item-text{
            font-size: 16px;
            font-weight: 400;
            color: #333333;
            text-align: center;
            margin-top: 13px;
          }
          &:hover{
            box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1)
          }
          &:nth-child(3n){
            margin-right: 0px;
          }
        }
      }
  }
  .home-news{
    width: 100%;
    min-height: 1229px;
    padding: 0px 18.75%;
    padding-top: 70px;
    padding-bottom: 80px;
    box-sizing: border-box;
    background-image: url('https://rapantaohui.oss-cn-hangzhou.aliyuncs.com/images/website/newsBack.png');
    background-size: 100% 100%;
    .headline{
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .headline-title{
            font-size: 30px;
            font-weight: 400;
            color: #000000;
          }
          .headline-interpret{
            font-size: 18px;
            font-weight: 400;
            color: #666666;
            margin-top: 21px;
            margin-bottom: 28px;
          }
          .headline-line{
            width: 39px;
            height: 1px;
            background-color: #666666;
          }
      }
      .news-list{
        width: 100%;
        margin-top: 80px;
        .news-item{
          width: 100%;
          height: 251px;
          background: #FFFFFF;
          border: 1px solid #EEEEEE;
          transition: box-shadow 0.4s;
          margin-top: 23px;
          padding: 26px 41px;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          cursor: pointer;
          &:hover{
            box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1)
          }
          .news-item-img{
            width: 286px;
            height: 199px;
          }
          .news-item-right{
            flex: 1;
            height: 199px;
            margin-left: 21px;
            position: relative;
            .item-right-title{
              font-size: 18px;
              font-weight: 400;
              color: #000000;
              margin-top: 12px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 1;/*省略几行写几*/
              -webkit-box-orient: vertical;
            }
            .item-right-text{
              width: 100%;
              font-size: 12px;
              font-weight: 400;
              color: #666666;
              line-height: 22px;
              margin-top: 18px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 3;/*省略几行写几*/
              -webkit-box-orient: vertical;
            }
            .item-right-time{
              font-size: 12px;
              font-family: PingFang SC;
              font-weight: 400;
              color: #999999;
              position: absolute;
              left: 0;
              bottom: 40px;
            }
            .item-right-btn{
              font-size: 14px;
              font-weight: 400;
              color: #DD5041;
              position: absolute;
              left: 0;
              bottom: 13px;
            }
          }
        }
        .news-more{
          width: 150px;
          height: 40px;
          background: #DD5041;
          border-radius: 2px;
          font-size: 18px;
          font-weight: 400;
          color: #FFFFFF;
          text-align: center;
          line-height: 40px;
          margin: 0 auto;
          margin-top: 59px;
          cursor: pointer;
        }
      }
  }
  .home-buddy{
    width: 100%;
    min-height: 676px;
    // padding: 0px 18.75%;
    padding-top: 100px;
    box-sizing: border-box;
    .headline{
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .headline-title{
            font-size: 30px;
            font-weight: 400;
            color: #000000;
          }
          .headline-interpret{
            font-size: 18px;
            font-weight: 400;
            color: #666666;
            margin-top: 21px;
            margin-bottom: 28px;
          }
          .headline-line{
            width: 39px;
            height: 1px;
            background-color: #666666;
          }
      }
      .home-buddy-list{
        width: 1300px;
        margin: 0 auto;
        margin-top: 78px;
        display: flex;
        justify-content: center;
        align-content: center;
        flex-wrap: wrap;
        .buddy-item{
          margin-right: 94px;
          margin-bottom: 118px;
          img{
            width: 100%;
            height: 100%;
          }
        }
      }
  }
  
</style>
