<template>
  <el-container>
    <el-header class="nav-header" height="102px">
      <img src="./assets/logo.png" class="logo" alt="">
      <nav>
        <router-link to="/">
          <div>首页</div>
        </router-link>
        <router-link to="/about">
          <div>关于我们</div>
        </router-link>
        <router-link to="/goods">
          <div>产品中心</div>
        </router-link>
        <router-link to="/news">
          <div>新闻动态</div>
        </router-link>
        <router-link to="/contact">
          <div>联系我们</div>
        </router-link>

      </nav>
    </el-header>
    <el-main><router-view /></el-main>
    <el-footer class="nav-Footer" height="295px">
      <div class="nav-Footer-top">
        <div class="Footer-top-left">
          <div class="Footer-top-left-title">蟠桃汇（杭州）互联网科技有限公司</div>
          <div class="Footer-top-left-item">
            <img style="width: 17px;height: 19px;" src="./assets/home/location.png" alt="" srcset="">
            <span>地址：浙江省杭州市钱塘区下沙街道金沙湖畔商业中心6幢7层</span>
          </div>
          <div class="Footer-top-left-item">
            <img style="width: 18px;height: 18px;" src="./assets/home/phone.png" alt="" srcset="">
            <span>电话：0571-888999</span>
          </div>
          <div class="Footer-top-left-item">
            <img style="width: 19px;height: 17px;" src="./assets/home/mailbox.png" alt="" srcset="">
            <span>邮箱: 407593529@qq.com</span>
          </div>
          <div class="Footer-top-left-item">
            <el-link href="https://beian.miit.gov.cn" :underline="false" style="color: #999999;"><span>第二类医疗器械备案号:
                浙杭药监械经营备20221168号</span></el-link>
          </div>
        </div>
        <div class="Footer-top-right">
          <div class="right-item">
            <img src="./assets/home/firmCode.png" alt="" srcset="">
            <span>企业微信二维码</span>
          </div>
          <div class="right-line"></div>
          <div class="right-item">
            <img src="./assets/home/officialCode.png" alt="" srcset="">
            <span>官方公众号二维码</span>
          </div>
        </div>
      </div>
      <div class="nav-Footer-bom">
        <div><el-link href="https://beian.miit.gov.cn" :underline="false"
            class="nav-Footer-bom-record">浙ICP备2020042875号-1</el-link></div>
        <div>互联网药品信息服务资格证: (浙) -经营 性-2020-0227</div>
        <div>医疗器械网络交易服务第一方平台备案号: (浙)网械平台备字[20211第00007号</div>
      </div>
    </el-footer>
    <div class="dialog" v-if="dialogStatus">
      <div class="dialogContent">
        <img class="dialogContentClose" @click="dialogStatus = false" src="./assets//home/dialogClose.png" alt="">
        <p class="dialogTitle">公安提醒·网络诈骗</p>
        <div class="dialogTip">
          <span>网络安全为人民</span>
          <span>网络安全靠人民</span>
        </div>
        <div class="dialogBtn" @click="onOpen()">立即查看</div>
      </div>
    </div>
  </el-container>

</template>
<script setup>
import { ref, reactive } from 'vue';
const dialogStatus = ref(true)
const onOpen = () => {
  let url = 'https://mp.weixin.qq.com/s/c0XWvjPYxbsLNr1YEo851w'
  dialogStatus.value = false
  window.open(url, '_blank');
}
</script>
<style>
* {
  margin: 0;
  padding: 0;
}
</style>
<style lang="scss" scoped>
.dialog {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;

  .dialogContent {
    width: 580px;
    height: 390px;
    background-image: url('./assets/home/dialog.png');
    background-repeat: no-repeat;
    background-size: contain;
    position: relative;
    line-height: 1;
    display: flex;
    flex-direction: column;
    align-items: center;

    .dialogContentClose {
      position: absolute;
      right: 29px;
      top: 29px;
      width: 20px;
      height: 20px;
      cursor: pointer;
    }

    .dialogTitle {
      font-size: 21px;
      color: #282728;
      font-weight: 600;
      margin: 195px 0 24px;
    }

    .dialogTip {
      font-size: 17px;
      color: #282728;
    }

    .dialogTip>span {
      margin-right: 16px;
    }

    .dialogTip>span:last-child {
      margin-right: 0;
    }

    .dialogBtn {
      width: 208px;
      height: 49px;
      background: #5B99F9;
      border-radius: 10px;
      margin-top: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      text-decoration: none;
    }
  }
}

.nav-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 18.75%;
  box-sizing: border-box;
  position: sticky;
  left: 0;
  top: 0;
  z-index: 999;
  background-color: #fff;

  .logo {
    width: 193px;
    height: 50px;
  }
}

nav {
  display: flex;
}

nav a {
  width: 112px;
  height: 102px;
  line-height: 102px;
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  color: #2c3e50;
  text-decoration: none;
  box-sizing: border-box;
  margin-right: 20px;
}

nav a.router-link-exact-active {
  color: #D32C25;
  border-bottom: 3px solid #D32C25;
  box-sizing: border-box;
}

.nav-Footer {
  width: 100%;
  padding: 0px;
  background-color: #fff;

  .nav-Footer-top {
    width: 100%;
    height: 205px;
    padding: 0px 18.75%;
    box-sizing: border-box;
    background-image: url('https://rapantaohui.oss-cn-hangzhou.aliyuncs.com/images/website/bomBackgrond.png');
    background-size: 100% 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .Footer-top-left {
      font-size: 14px;
      font-weight: 400;
      color: #999999;

      .Footer-top-left-title {
        font-size: 20px;
        font-weight: 400;
        color: #FFFFFF;
        margin-bottom: 17px;
      }

      .Footer-top-left-item {
        display: flex;
        align-items: center;
        margin-bottom: 14px;

        img {
          margin-right: 11px;
        }

        &:last-child {
          margin-bottom: 0px;
        }
      }
    }

    .Footer-top-right {
      display: flex;
      align-items: center;

      .right-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        font-weight: 400;
        color: #999999;

        img {
          width: 80px;
          height: 80px;
          margin-bottom: 11px;
        }

      }

      .right-line {
        width: 1px;
        height: 105px;
        background: #38393D;
        margin: 0 30px;
      }
    }
  }

  .nav-Footer-bom {
    width: 100%;
    height: 90px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #999999;

    div {
      margin-bottom: 7px;
    }

    .nav-Footer-bom-record {
      font-size: 12px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #999999;
    }
  }
}

.el-main {
  padding: 0px;
  // overflow: visible;
}
</style>
