import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue')
  },{
    path: '/goods',
    name: 'goods',
    component: () => import('../views/GoodsView.vue')
  },{
    path: '/news',
    name: 'news',
    component: () => import('../views/newsView.vue')
  },{
    path: '/contact',
    name: 'contact',
    component: () => import( '../views/contactView.vue')
  },{
    path: '/detail:data',
    name: 'detail',
    component: () => import( '../views/newsDetailView.vue')
  }
]
const  scrollBehavior = (to, from, savedPosition) => {
  if (savedPosition && to.meta.keepAlive) {
    return savedPosition;
  }
  return { left: 0, top:0 };
}
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior
})

export default router
